import { createStore } from 'vuex'

const MenuRecepcionStore = {
  state: {
    tab: 0
  },
  getters: {
    _tab: (state) => state.recepcion
  },
  mutations: {
    setTab (state, payload) {
      state.recepcion = payload
    }
  },
  actions: {
  }
}
const store = createStore(MenuRecepcionStore)
export default {
  namespaced: true,
  ...store
}
