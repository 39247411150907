import { createStore } from 'vuex'
import {
  getResolucionBod,
  getGuide,
  postGuide,
  getMx,
  getBodegas,
  getDireccionBod,
  getDireccionBodDestino,
  verOficioTransferencia,
  getBatchNumber,
  getPaginate,
  getInfoModal,
  getUsers,
  exportExcel,
  getTarifas,
  infoEnvio,
  getBox
} from '../services/controlEnvioService'
import {
  getServicios,
  getEmpaques,
  getCentrosCostos,
  getCiudades,
  getMensajeros
} from '../services/encargaTuDomi.service'

const storeControlEnvio = {
  state: {
    _step1: {
      bod_origen: null,
      bod_destino: null,
      user_responsable: null,
      nameUser_responsable: null,
      created_by: null,
      created_at: null,
      bodOrigenDir: null,
      bodDestinoDir: null,
      OrigenWhsName: null,
      DestinoWhsName: null,
      recepcion_responsable: null,
      absEntry_destino: null,
      absEntry_origen: null,
      escala: false
    },
    _step2: {
      tipoTransferencia: null,
      dataTable: null,
      docNumEntrada: null,
      detailsTemp: [],
      details: [],
      // cajas: [],
      asignarMx: []
    },
    _step3: {
      cajas: [{
        indiceLabel: 'MOSTRAR TODOS',
        numero: 99999,
        cadena_frio: false,
        temperatura: 0,
        temp_entrada: 0,
        idEnvio: null,
        cantidad: 0,
        estado: false
      }],
      contenidoCajas: [],
      medicamentos: []
    },
    _resolucionBod: [],
    _valorEnvio: [],
    _tiposServicios: [],
    _empaques: [],
    _centrosCostos: [],
    _user: {},
    _ciudades: [],
    _mensajeros: [],
    _tiposDocumentos: [
      { tipdoc: 1, name: 'Cédula de Ciudadania' },
      { tipdoc: 2, name: 'NIT' },
      { tipdoc: 3, name: 'Cédula de Extranjería' },
      { tipdoc: 4, name: 'Registro Civil' },
      { tipdoc: 5, name: 'Tarjeta de Identidad' },
      { tipdoc: 6, name: 'Identificación de extranjeros dif a NIT' },
      { tipdoc: 7, name: 'Pasaporte' }
    ]
  },
  getters: {
    getStep1: state => state._step1,
    getStep2: state => state._step2,
    getStep3: state => state._step3,
    getMx: state => state._step3.medicamentos,
    getResolucionBod: state => state._resolucionBod,
    getValorEnvio: state => state._valorEnvio,
    getTiposServicios: state => state._tiposServicios,
    getEmpaques: state => state._empaques,
    getCentrosCostos: state => state._centrosCostos,
    getUser: state => state._user,
    getCiudades: state => state._ciudades,
    getMensajeros: state => state._mensajeros,
    getTiposDocumentos: state => state._tiposDocumentos
  },
  mutations: {
    setStep1 (state, payload) {
      state._step1 = {
        bod_origen: payload.bod_origen ?? null,
        bod_destino: payload.bod_destino ?? null,
        recepcion_responsable: payload.recepcion_responsable ?? null,
        user_responsable: payload.user_responsable ?? null,
        bodDestinoDir: payload.bodDestinoDir ?? null,
        OrigenWhsName: payload.OrigenWhsName ?? null,
        DestinoWhsName: payload.DestinoWhsName ?? null,
        absEntry_destino: payload.absEntry_destino ?? null,
        bodOrigenDir: payload.bodOrigenDir ?? null,
        absEntry_origen: payload.absEntry_origen ?? null,
        escala: payload.escala ?? false
      }
    },
    setStep2 (state, payload) {
      state._step2 = {
        tipoTransferencia: payload.tipoTransferencia ?? null,
        estado: 0,
        dataTable: payload.dataTable ?? null,
        docNumEntrada: payload.docNumEntrada ?? null,
        detailsTemp: payload.detailsTemp ?? [],
        details: payload.details ?? []
      }
    },
    onSetMx (state, payload) {
      state._step3.medicamentos = payload
    },
    setValorEnvio (state, payload) {
      state._valorEnvio = payload
    },
    onResetMx (state) {
      state._step3.medicamentos = []
    },
    onSetBoxes (state, payload) {
      const boxes = state._step3.cajas.filter((a) => a.numero === 99999)
      for (const element of payload.cajas) {
        boxes.push(element)
      }
      state._step3 = {
        contenidoCajas: payload.contenidoCajas,
        cajas: boxes
      }
    },
    onSetDetails (state, payload) {
      for (const element of payload) {
        state._step2.details.push(element)
      }
    },
    onResetStep3 (state) {
      state._step3 = {
        cajas: [{
          indiceLabel: 'MOSTRAR TODOS',
          numero: 99999,
          cadena_frio: false,
          temperatura: 0,
          temp_entrada: 0,
          idEnvio: null,
          cantidad: 0,
          estado: false
        }],
        contenidoCajas: [],
        medicamentos: []
      }
    },
    onResetStep2 (state) {
      state._step2 = {
        tipoTransferencia: null,
        estado: 0,
        dataTable: null,
        docNumEntrada: null,
        detailsTemp: [],
        details: [],
        cajas: []
      }
    },
    onResetStep1 (state) {
      state._step1 = {
        bod_origen: null,
        bod_destino: null,
        recepcion_responsable: null,
        user_responsable: null,
        bodDestinoDir: null,
        absEntry_destino: null,
        bodOrigenDir: null,
        absEntry_origen: null
      }
    },
    setResolucionBod (state, payload) {
      state._resolucionBod = payload
    },
    setServicios (state, payload) {
      state._tiposServicios = payload.filter((a) => a.nomTipo === 'BANCARIO' ||
          a.nomTipo === 'FREIGHTEXPRES 10 Ton' ||
          a.nomTipo === 'FREIGHTEXPRES 3 Ton' ||
          a.nomTipo === 'FREIGHTEXPRES 30 Ton' ||
          a.nomTipo === 'FREIGHTEXPRES 5 Ton' ||
          a.nomTipo === 'VANSEXPRES 900 KG' ||
          a.nomTipo === 'ENCARGACARE' ||
          a.nomTipo === 'TUCARGA' ||
          a.nomTipo === 'PRIORITY' ||
          a.nomTipo === 'ENCARGAHOY' ||
          a.nomTipo === 'EXPRESS')
    },
    setEmpaques (state, payload) {
      state._empaques = payload
    },
    setCentrosCostos (state, payload) {
      state._centrosCostos = payload
    },
    setUser (state, payload) {
      state._user = payload
    },
    setCiudades (state, payload) {
      state._ciudades = payload
    },
    setMensajeros (state, payload) {
      state._mensajeros = payload
    }
  },
  actions: {
    getControlEnvio (state, params) {
      return getGuide(params)
    },
    postControlEnvio (state, params) {
      return postGuide(params)
    },
    getMx (state, params) {
      return getMx(params)
    },
    getBox (state, params) {
      return getBox(params)
    },
    getBodegas (state, params) {
      return getBodegas(params)
    },
    getDireccionBod (state, params) {
      return getDireccionBod(params)
    },
    getDireccionBodDestino (state, params) {
      return getDireccionBodDestino(params)
    },
    oficioTransferencia (state, params) {
      return verOficioTransferencia(params)
    },
    getBatchNumber (state, params) {
      return getBatchNumber(params)
    },
    getPaginate (state, params) {
      return getPaginate(params)
    },
    getInfoModal (state, params) {
      return getInfoModal(params)
    },
    getUsers () {
      return getUsers()
    },
    exportExcel (state, params) {
      return exportExcel(params)
    },
    getTarifas (state, params) {
      return getTarifas(params)
    },
    infoEnvio (state, params) {
      return infoEnvio(params)
    },
    getResolucion ({ commit }, whsCode) {
      return getResolucionBod(whsCode).then(({ data }) => {
        commit('setResolucionBod', data)
      })
    },
    actionGetServicios ({ commit, state }, params) {
      return getServicios(state._user.MyToken).then(({ data }) => {
        commit('setServicios', data.table)
      })
    },
    actionGetEmpaques ({ commit, state }, params) {
      return getEmpaques(state._user.MyToken).then(({ data }) => {
        commit('setEmpaques', data.table)
      })
    },
    actionGetCentrosCostos ({ commit, state }, params) {
      return getCentrosCostos(state._user.MyToken).then(({ data }) => {
        commit('setCentrosCostos', data.centros)
      })
    },
    actionGetCiudades ({ commit, state }, params) {
      return getCiudades(state._user.MyToken).then(({ data }) => {
        commit('setCiudades', data.table)
      })
    },
    actionGetMensajeros ({ commit, state }, params) {
      return getMensajeros(state._user.MyToken).then(({ data }) => {
        commit('setMensajeros', data.mensajeros)
      })
    }
  }
}
const store = createStore(storeControlEnvio)
export default {
  namespaced: true,
  ...store
}
