import http from '@/libs/http'
import axios from 'axios'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_CONTROL_ENVIO
const getGuide = (params) => http.get(`${baseUrl}/getGuide/${params}`, { headers: { loading: true } })
const postGuide = (data) => http.post(`${baseUrl}/crear`, data, { headers: { loading: true } })
const getMx = (params) => http.post(`${baseUrl}/getMx`, params, { headers: { loading: true } })
const getBox = (params) => http.get(`${baseUrl}/getBox/${params}`, { headers: { loading: true } })
const getBodegas = () => http.get(`${baseUrl}/getBodegas`, { headers: { loading: true } })
const getDireccionBod = (params) => http.get(`${baseUrl}/getBodegaDir/${params}`, { headers: { loading: true } })
const getDireccionBodDestino = (params) => http.get(`${baseUrl}/getBodegaDirDestino/${params}`, { headers: { loading: true } })
const verOficioTransferencia = ({ idEnvio, tipoOficio }) => {
  const url = `${baseUrl}/oficio-transferencia/${idEnvio}/${tipoOficio}`
  const res = axios.getUri({ url })
  window.open(res, '_blank')
}
const getBatchNumber = (params) => http.post(`${baseUrl}/getBatchNumber`, params, { headers: { loading: true } })
const getPaginate = (payload) => {
  return http.get(`${baseUrl}/getPaginate`, { params: { ...payload } }, {
    headers: {
      loading: true
    }
  })
}
const getInfoModal = (payload) => http.get(`${baseUrl}/getInfoModal/${payload}`, { headers: { loading: true } })
const getUsers = () => http.get(`${baseUrl}/getUsers`, { headers: { loading: true } })
const exportExcel = (params) => {
  return new Promise((resolve) => {
    const url = `${baseUrl}/exportExcel`
    const res = axios.getUri({ url, params })
    resolve(res)
  })
}
const getTarifas = (idTransportista) => http.get(`${baseUrl}/getTarifas/${idTransportista}`, { headers: { loading: true } })
const infoEnvio = (id) => {
  const url = `${baseUrl}/infoEnvio/${id}`
  const res = axios.getUri({ url })
  window.open(res, '_blank')
}
const getResolucionBod = (whsCode) => http.get(`${baseUrl}/resolucion-bodegas/${whsCode}`, { headers: { loading: true } })

export {
    getGuide,
    postGuide,
    getMx,
    getBodegas,
    getDireccionBod,
    getDireccionBodDestino,
    verOficioTransferencia,
    getBatchNumber,
    getPaginate,
    getInfoModal,
    getUsers,
    exportExcel,
    getTarifas,
    infoEnvio,
    getResolucionBod,
    getBox
  }
