<template>
    <section class="w-full">
      <div class="py-4 px-2">
        <!-- Filtros -->
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-9 gap-4 mb-4 lg:mb-0">
          <div class="field">
            <label for="icon">Fecha inicial</label>
            <input :max="filtros.fechaFinal" class="p-button-secondary w-full border p-2 rounded-md mb-2 mt-1 flex-items-center" v-model="filtros.fechaInicial" type="date">
          </div>
          <div class="field">
            <label for="icon">Fecha final</label>
            <input :min="filtros.fechaInicial" class="p-button-secondary w-full border p-2 rounded-md mb-2 mt-1 flex-items-center" v-model="filtros.fechaFinal" type="date">
          </div>
          <div class="field grid">
            <label for="nombre">Guia</label>
            <InputText id="nombre" v-model="filtros.guia" class="w-full mb-2" type="text" placeholder="" />
          </div>
          <div class="field grid">
            <label for="nombre">Oficio</label>
            <InputText id="nombre" v-model="filtros.oficio" class="w-full mb-2" type="text" placeholder="" />
          </div>
          <div class="field grid">
            <label for="nombre">Novedades</label>
            <Dropdown v-model="filtros.novedades" class="rounded-md border-gray-300 w-full mb-2" :options="novedadesFiltro" placeholder="" />
          </div>
          <div class="field grid">
            <label for="nombre">Estado</label>
            <Dropdown v-model="filtros.estado" class="rounded-md border-gray-300 w-full mb-2" :options="estados" placeholder="" />
          </div>
          <div class="field grid">
            <label for="nombre">Bodega destino</label>
            <div class="flex mb-2">
              <Dropdown
                class="rounded-md border-gray-300 w-full lg:w-40"
                v-model="filtros.bodegaDestino"
                :options="bodegas"
                optionLabel="bodega"
                :filter="true"
                placeholder="" />
            </div>
          </div>
          <div class="pt-6 pb-2 w-full flex gap-2">
            <Button @click="buscar" class="border-0 w-full" label="Buscar" />
          </div>
          <div class="pt-6 pb-2 w-full flex gap-2">
            <Button @click="limpiar" class="border-0 p-button-outlined p-button-secondary w-full" label="Limpiar" />
          </div>
        </div>
        <Paginator v-model:first="offset"
          class="md:hidden"
          :rows="limit"
          :totalRecords="totalItemsCount"
          :rowsPerPageOptions="[2,10,20,30]"
          @page="onPage($event)"
        />
        <DataTable
          :value="recepciones"
          class="p-datatable-sm"
          dataKey="id"
          filterDisplay="menu"
          responsiveLayout="scroll"
        >
          <template #empty>
            No existen recepciones registradas
          </template>
          <Column field="id" header="Recepcion" style="color: #4A4A4A;" class="my-1 py-1 shadow-sm lg:text-xs">
            <template #body="{data}">
              <div class="grid grid-cols-2 gap-2 w-32">
                <div><p class="font-bold">ID:</p></div>
                <div><p>{{ data.id }}</p></div>
                <div><p class="font-bold">Guia:</p></div>
                <div><p>{{ data.numGuia }}</p></div>
                <div><p class="font-bold">Oficio:</p></div>
                <div><p>{{ data.oficio }}</p></div>
              </div>
            </template>
          </Column>
          <Column header="Bodega Origen"
            dataType="date"
            style="color: #4A4A4A;"
            class="shadow-sm"
            headerClass="lg:text-xs"
          >
            <template #body="{data}">
              <div class="flex flex-col w-32">
                <p class="lg:text-xs flex items-center">
                  {{ data.bodNameOrigen }}
                </p>
                <p class="lg:text-xs flex items-center">
                  {{ data.bodOrigen }}
                </p>
                <p class="lg:text-xs flex items-center">
                  {{ data.dirOrigen }}
                </p>
              </div>
            </template>
          </Column>
          <Column header="Bodega Destino"
            headerClass="lg:text-xs"
            dataType="date"
            style="color: #4A4A4A;"
            class="shadow-sm"
          >
            <template #body="{data}">
              <div class="flex flex-col w-32">
                <p class="lg:text-xs flex items-center">
                  {{ data.bodNameDestino }}
                </p>
                <p class="lg:text-xs flex items-center">
                  {{ data.bodDestino }}
                </p>
                <p class="lg:text-xs flex items-center">
                  {{ data.dirDestino }}
                </p>
              </div>
            </template>
          </Column>
          <Column header="Responsables"
            headerClass="lg:text-xs"
            dataType="date"
            style="color: #4A4A4A;"
            class="shadow-sm"
          >
            <template #body="{data}">
              <div class="flex text-xs gap-2">
                <div>
                  <ul>
                    <li><p class="font-bold">Resp. envio:</p></li>
                    <li class="my-1"><p class="font-bold">Resp. entrega:</p></li>
                    <li><p class="font-bold">Resp. recepcion:</p></li>
                  </ul>
                </div>
                <div class="mx-2">
                  <ul>
                    <li><p>{{ data.enviadoPor }}</p></li>
                    <li class="my-1"><p>{{ data.recibidoPor }}</p></li>
                    <li><p>{{ data.resposableRecepcion }}</p></li>
                  </ul>
                </div>
              </div>
            </template>
          </Column>
          <Column header="Cantidades"
            headerClass="lg:text-xs"
            dataType="date"
            style="color: #4A4A4A;"
            class="shadow-sm"
          >
            <template #body="{data}">
              <div class="grid grid-cols-2 gap-2 lg:text-xs">
                <div><p class="font-bold">Articulos:</p></div>
                <div><p>{{ data.totalMx }}</p></div>
                <div><p class="font-bold">Cajas:</p></div>
                <div><p>{{ data.totalCajas }}</p></div>
              </div>
            </template>
          </Column>
          <Column header="Fechas"
            headerClass="lg:text-xs"
            dataType="date"
            style="color: #4A4A4A;"
            class="shadow-sm text-center"
          >
            <template #body="{data}">
              <div class="grid grid-cols-2 gap-2 lg:text-xs">
                <div><p class="font-bold">Envio:</p></div>
                <div><p>{{ data.fechaEnvio }}</p></div>
                <div v-if="data.estado === 'POR RECEPCIONAR' || data.estado === 'EN STOCK'"><p class="font-bold">Entrega:</p></div>
                <div v-if="data.estado === 'POR RECEPCIONAR' || data.estado === 'EN STOCK'"><p>{{ data.fechaEntrega }}</p></div>
                <div v-if="data.estado === 'EN STOCK'"><p class="font-bold">Recepción:</p></div>
                <div v-if="data.estado === 'EN STOCK'"><p>{{ data.fechaRecepcion }}</p></div>
              </div>
            </template>
          </Column>
          <Column header="Estado"
            headerClass="lg:text-xs"
            dataType="date"
            style="max-width:8.5rem; color: #4A4A4A;"
            class="shadow-sm text-center"
          >
            <template #body="{data}">
              <div class="w-32 lg:w-full">
                <Button
                  :badge="(data.estado === 'EN TRANSITO' ? (calcularDiasEntrega(data) ? '!' : '') : data.cajasSinRegistrar > 0 ? '!' : '')"
                  v-tooltip.bottom="`${calcularDiasEntrega(data) ? 'Esta recepcion se encuentra retrasada' : data.estado}`"
                  badgeClass="p-badge-danger"
                  :label="data.estado"
                  :class="data.estado === 'EN STOCK' ? 'recepcion-stock' : (data.estado === 'EN TRANSITO' ? 'recepcion-en-transito' : (data.estado === 'POR RECEPCIONAR' ? 'recepcion-por-recepcionar' : 'recepcion-escala'))"
                  class="p-button-outlined lg:text-xs w-full py-1 font-bold" />
              </div>
            </template>
          </Column>
          <Column header="Acciones"
            v-if="$can('pharmasan.logistica.recepcion-tecnica.access')"
            headerClass="lg:text-xs bg-white"
            dataType="date"
            class="shadow-sm text-center px-5 borde-tabla"
            style="color: #4A4A4A;"
          >
            <template #body="{data}">
              <div>
                <!-- <button
                  class=" dropdown-toggle px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg active:text-white transition duration-150 ease-in-out flex items-center whitespace-nowrap"
                  type="button"
                  id="prueba"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="pi pi-cog"/>
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" class="w-2 ml-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"></path></svg>
                </button>
                <ul
                  class="dropdown-menu min-w-max absolute hidden bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 hidden m-0 bg-clip-padding border-none"
                  aria-labelledby="prueba"
                >
                  <li>
                    <Button
                      @click="$router.push({name: 'logistica.recepcion.recepcion', params: {id: data.id}})"
                      class="border-0 bg-green-500 w-9"
                      style="padding-top: 0.20rem; padding-left: 0.60rem; padding-right: 0.60rem;"
                    >
                      <img class="my-1" style="width: 1rem;" src="../../../../../../../assets/images/hand-money-white.svg" alt="">
                    </Button >
                  </li>
                  <li>
                    <Button @click="openModalTransito(data.id)" class="border-0 w-9" icon="pi pi-eye" />
                  </li>
                  <li>
                    <Dropdown  @change="verPdf(data)" v-model="fileToOpen" class="bg-gray-600 w-9" :options="verDocumentosSelect">
                        <template #value="slotProps">
                            <div class="p-dropdown-car-value">
                                <i class="text-white" style="margin-left: 0.1rem; margin-right: 0.1rem;" :class="slotProps ? 'pi pi-file' : 'pi pi-file'"></i>
                            </div>
                        </template>
                        <template #option="slotProps">
                        <div class="p-dropdown-car-option">
                          <span>{{slotProps.option}}</span>
                        </div>
                      </template>
                    </Dropdown>
                  </li>
                  <li>
                    <Button v-if="data.pathActa" @click="verActa(data)" class="border-0 p-button-danger w-9" icon="pi pi-file-pdf" />
                    <Button v-else @click="openModalUploadActa(data)" class="border-0 p-button-info w-9" icon="pi pi-upload" />
                  </li>
                </ul> -->
                <div class="flex justify-around w-full" v-if="data.estado === 'POR RECEPCIONAR'">
                  <Button
                    @click="$router.push({name: 'pharmasan.compras.logistica.recepcion-tecnica.recepcion', params: {id: data.id}})"
                    class="border-0 bg-green-500 w-9"
                    style="padding-top: 0.20rem; padding-left: 0.60rem; padding-right: 0.60rem;"
                  >
                    <img class="my-1" style="width: 1rem;" src="../../../../../../../../assets/images/hand-money-white.svg" alt="">
                  </Button >
                  <Button @click="openModalTransito(data.id)" class="border-0 w-9" icon="pi pi-eye" />
                  <Dropdown  @change="verPdf(data)" v-model="fileToOpen" class="bg-gray-600 w-9" :options="verDocumentosSelect">
                      <template #value="slotProps">
                          <div class="p-dropdown-car-value">
                              <i class="text-white" style="margin-left: 0.1rem; margin-right: 0.1rem;" :class="slotProps ? 'pi pi-file' : 'pi pi-file'"></i>
                          </div>
                      </template>
                      <template #option="slotProps">
                      <div class="p-dropdown-car-option">
                        <span>{{slotProps.option}}</span>
                      </div>
                    </template>
                  </Dropdown>
                  <Button v-if="data.pathActa" @click="verActa(data)" class="border-0 p-button-danger w-9" icon="pi pi-file-pdf" />
                  <Button v-else @click="openModalUploadActa(data)" class="border-0 p-button-info w-9" icon="pi pi-upload" />
                </div>
                <div class="flex justify-around w-full" v-if="data.estado === 'EN STOCK' || data.estado === 'EN ESCALA'">
                  <Button v-if="data.totalNovedades === 0" v-tooltip.bottom="'Este oficio no cuenta con novedades'" class="p-button-warning border-0 disabled:opacity-60 opacity-60 " icon="pi pi-list" style="width: 2.2rem;" />
                  <Button v-else @click="openModalNovedades(data)" icon="pi pi-list" class="border-0  p-button-warning" style="width: 2.2rem;" />
                  <Button @click="openModal(data)" class="border-0 " icon="pi pi-eye" style="width: 2.2rem;" />
                  <Dropdown  @change="verPdf(data)" v-model="fileToOpen" class="bg-gray-600 " :options="verDocumentosSelect">
                      <template #value="slotProps">
                          <div class="p-dropdown-car-value">
                              <i class="text-white" style="margin-left: 0.1rem; margin-right: 0.1rem;" :class="slotProps ? 'pi pi-file' : 'pi pi-file'"></i>
                          </div>
                      </template>
                      <template #option="slotProps">
                      <div class="p-dropdown-car-option">
                        <span>{{slotProps.option}}</span>
                      </div>
                    </template>
                  </Dropdown>
                  <Button v-if="data.pathActa" @click="verActa(data)" class="border-0 p-button-danger w-9" icon="pi pi-file-pdf" />
                  <Button v-else @click="openModalUploadActa(data)" class="border-0 p-button-info w-9" icon="pi pi-upload" />
                </div>
                <div class="flex justify-around w-full" v-if="data.estado === 'EN TRANSITO'">
                  <Button
                    @click="$router.push({name: 'pharmasan.compras.logistica.recepcion-tecnica.recepcion', params: {id: data.id}})"
                    class="border-0 bg-green-500"
                    style="padding-top: 0.20rem; padding-left: 0.60rem; padding-right: 0.60rem;"
                    disabled="disabled"
                  >
                    <img class="my-1" style="width: 1rem;" src="../../../../../../../../assets/images/hand-money-white.svg" alt="">
                  </Button >
                  <Button @click="openModalTransito(data.id)" class="border-0" icon="pi pi-eye" style="width: 2.2rem;" />
                  <Dropdown  @change="verPdf(data)" v-model="fileToOpen" class="bg-gray-600" :options="verDocumentosSelect">
                      <template #value="slotProps">
                          <div class="p-dropdown-car-value">
                              <i class="text-white" style="margin-left: 0.1rem; margin-right: 0.1rem;" :class="slotProps ? 'pi pi-file' : 'pi pi-file'"></i>
                          </div>
                      </template>
                      <template #option="slotProps">
                      <div class="p-dropdown-car-option">
                        <span>{{slotProps.option}}</span>
                      </div>
                    </template>
                  </Dropdown>
                  <Button v-if="data.pathActa" @click="verActa(data)" class="border-0 p-button-danger w-9" icon="pi pi-file-pdf" />
                  <Button v-else @click="openModalUploadActa(data)" class="border-0 p-button-info w-9" icon="pi pi-upload" />
                </div>
              </div>
            </template>
          </Column>
        </DataTable>
        <Paginator v-model:first="offset"
          :rows="limit"
          :totalRecords="totalItemsCount"
          :rowsPerPageOptions="[2,10,20,30]"
          @page="onPage($event)"
        />
      </div>
    </section>
    <Dialog v-model:visible="displayModal" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
      <template #header>
        <div class="flex jusstify-between">
          <h3 class="font-bold">ID {{dataModalRecepcion.id}}</h3>
        </div>
      </template>
      <div class="w-full">
        <div class="lg:flex lg:justify-between lg:text-xs mb-2">
          <div class="grid grid-cols-3 gap-2">
            <div>
              <label class="mb-4" for="">Fecha de Envío</label>
              <p class="font-bold">
                {{dataModalRecepcion.fechaEnvio}}
              </p>
            </div>
            <div>
              <label class="mb-4" for="">N° Guía</label>
              <div class="flex">
                <i class="pi pi-file lg:text-xs"></i>
                <p class="font-bold">
                  {{dataModalRecepcion.numGuia}}
                </p>
              </div>
            </div>
            <div>
              <label class="mb-4" for="">N° Oficio</label>
              <div class="flex">
                <i class="pi pi-file lg:text-xs"></i>
                <p class="font-bold">
                  {{dataModalRecepcion.oficio}}
                </p>
              </div>
            </div>
          </div>
          <div class="flex gap-8 my-5 lg:my-0">
            <div>
              <div class="flex">
                <p class="mr-2">
                  Bodega Origen:
                </p>
                <img class="w-10" src="../../../../../../../../assets/images/arrow-right-long.svg" alt="">
              </div>
              <div class="flex gap-2">
                <p class="font-bold">
                  {{dataModalRecepcion.bodOrigen}}
                </p>
                <p class="font-bold">
                  {{dataModalRecepcion.bodNameOrigen}}
                </p>
              </div>
            </div>
            <div>
              <div>
                <p>
                  Bodega Destino:
                </p>
              </div>
              <div class="flex gap-2">
                <p class="font-bold">
                  {{dataModalRecepcion.bodDestino}}
                </p>
                <p class="font-bold">
                  {{dataModalRecepcion.bodNameDestino}}
                </p>
              </div>
            </div>
          </div>
          <div>
            <label class="mb-4" for="">Estado</label>
            <p class="font-bold">
              {{dataModalRecepcion.estado}}
            </p>
          </div>
        </div>
        <DataTable
          :value="dataModalRecepcion.Medicamentos"
          responsiveLayout="scroll"
          headerClass="bg-white"
        >
          <template #empty>
            No existen usuarios registradas
          </template>
          <Column field="oficio" header="N° Articulo" headerClass="lg:text-xs" class="shadow-sm" style="color: #4A4A4A;">
            <template #body="{data}">
            <p class="lg:text-xs flex items-center">
              {{data.Articulo}}
            </p>
            </template>
          </Column>
          <Column field="oficio" header="Medicamento" headerClass="lg:text-xs" class="shadow-sm" style="color: #4A4A4A;">
            <template #body="{data}">
            <p class="lg:text-xs flex items-center">
              {{data.Medicamento}}
            </p>
            </template>
          </Column>
          <Column header="Cantidad"
            headerClass="lg:text-xs"
            dataType="date"
            style="color: #4A4A4A;"
            class="shadow-sm"
          >
            <template class="lg:text-xs" #body="{data}">
              <p class="lg:text-xs flex items-center">
                {{ data.cantEnv }}
              </p>
            </template>
          </Column>
          <Column header="Lote"
            headerClass="lg:text-xs"
            dataType="date"
            style="color: #4A4A4A;"
            class="shadow-sm"
          >
            <template class="lg:text-xs" #body="{data}">
              <p class="lg:text-xs flex items-center">
                {{ data.lote }}
              </p>
            </template>
          </Column>
          <Column header="Fecha de vencimiento"
            headerClass="lg:text-xs"
            dataType="date"
            style="color: #4A4A4A;"
            class="shadow-sm"
          >
            <template class="lg:text-xs" #body="{data}">
              <p class="lg:text-xs flex items-center">
                {{ data.fechaVencimiento }}
              </p>
            </template>
          </Column>
        </DataTable>
        <div class="my-4" v-if="dataModalRecepcion.estado === 'EN TRANSITO' && showCardUploadGuide">
          <div class="shadow-md p-4 flex justify-center">
            <div class="w-full md:w-6/12 border-dashed rounded-md">
              <FilePond ref="pond"/>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <div v-if="dataModalRecepcion.estado === 'POR RECEPCIONAR'" class="w-full flex justify-center gap-4">
          <Button label="CANCELAR"  @click="displayModal = false" class="bg-gray-300 hover:bg-gray-300 text-gray-800 hover:text-gray-800 border-0"/>
          <Button label="RECEPCIONAR"  @click="$router.push({name: 'pharmasan.compras.logistica.recepcion-tecnica.recepcion', params: {id: dataModalRecepcion.id}})" class="bg-green-400 hover:bg-green-400 text-white hover:text-white border-0" />
        </div>
      </template>
    </Dialog>
    <Dialog v-model:visible="displayModalTransito" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true" :closable="false">
      <template #header>
        <div class="flex w-full">
          <div class="w-6/12">
            <h3 class="font-bold">ID {{infoModalTransito.id}}</h3>
          </div>
          <div class="flex items-center w-6/12 justify-end">
            <div class="flex" v-if="(medicamentosTrasito.some(a => a.estadoCaja === false) && infoModalTransito.estado === 'POR RECEPCIONAR')">
              <i class="pi pi-exclamation-circle text-red-600"></i>
              <p class="mx-2 text-sm">
                Este oficio tiene <span class="font-bold">{{ detalleCajas.length }}</span> cajas sin registrar
              </p>
            </div>
            <label for="barCodeBoxCajaId">
              <svg style="width:24px;height:24px; cursor: pointer" viewBox="0 0 24 24">
                  <path fill="black" d="M4,6H6V18H4V6M7,6H8V18H7V6M9,6H12V18H9V6M13,6H14V18H13V6M16,6H18V18H16V6M19,6H20V18H19V6M2,4V8H0V4A2,2 0 0,1 2,2H6V4H2M22,2A2,2 0 0,1 24,4V8H22V4H18V2H22M2,16V20H6V22H2A2,2 0 0,1 0,20V16H2M22,20V16H24V20A2,2 0 0,1 22,22H18V20H22Z" />
              </svg>
            </label>
            <input
              id="barCodeBoxCajaId"
              ref="barCodeBoxCajaFocus"
              @keyup.enter="notificarCaja(barCodeBox, 1)"
              v-model="barCodeBox"
              :class="barCodeBox ? 'bg-green-600 border-green-600 text-green-600': ''"
              class="mx-2 bg:white border-0 w-2 h-3 rounded-full text-green-600 focus:border-green-600 focus:bg-green-600 focus:shadow-md "
              style="width: 10px;"
              type="text"
              autofocus
            >
            <p class="ml-2">
              {{barCodeBox}}
            </p>
            <button v-if="barCodeBox" @click="barCodeBox = ''" class="rounded-full mx-2 w-4 flex items-center justify-center h-4 bg-red-600">
              <i class="pi pi-times text-white" style="font-size: 0.5rem;"></i>
            </button>
          </div>
        </div>
      </template>
      <div class="flex gap-4">
        <div class="w-full">
          <div class="lg:flex lg:justify-between lg:text-xs mb-2">
            <div class="grid grid-cols-3 gap-2">
              <div>
                <label class="mb-4" for="">Fecha de Envío</label>
                <p class="font-bold">
                  {{infoModalTransito.fechaEnvio}}
                </p>
              </div>
              <div>
                <label class="mb-4" for="">N° Guía</label>
                <div class="flex">
                  <i class="pi pi-file lg:text-xs"></i>
                  <p class="font-bold">
                    {{infoModalTransito.numGuia}}
                  </p>
                </div>
              </div>
              <div>
                <label class="mb-4" for="">N° Oficio</label>
                <div class="flex">
                  <i class="pi pi-file lg:text-xs"></i>
                  <p class="font-bold">
                    {{infoModalTransito.numOficio}}
                  </p>
                </div>
              </div>
            </div>
            <div class="flex gap-8 my-5 lg:my-0">
              <div>
                <div class="flex">
                  <p class="mr-2">
                    Bodega Origen:
                  </p>
                  <img class="w-10" src="../../../../../../../../assets/images/arrow-right-long.svg" alt="">
                </div>
                <div class="flex gap-2">
                  <p class="font-bold">
                    {{infoModalTransito.bodOrigen}}
                  </p>
                  <p class="font-bold">
                    {{infoModalTransito.bodNameOrigen}}
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <p>
                    Bodega Destino:
                  </p>
                </div>
                <div class="flex gap-2">
                  <p class="font-bold">
                    {{infoModalTransito.bodDestino}}
                  </p>
                  <p class="font-bold">
                    {{infoModalTransito.bodNameDestino}}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <label class="mb-4" for="">Estado</label>
              <p class="font-bold">
                {{infoModalTransito.estado}}
              </p>
            </div>
          </div>
          <div class="flex gap-2">
            <DataTable
              class="p-datatable-sm w-full"
              :value="medicamentosTrasito"
              rowGroupMode="subheader"
              groupRowsBy="cajaId"
              sortMode="single"
              sortField="cajaId"
              :sortOrder="1"
              responsiveLayout="scroll"
              :showGridlines="false"
              :stripedRows="false"
              :expandableRowGroups="true"
              v-model:expandedRowGroups="expandedRowGroups"
            >
                <Column field="cajaId" header="Caja" headerClass="lg:text-xs"></Column>
                <Column field="itemName" header="Medicamento" bodyClass="lg:text-xs" headerClass="lg:text-xs">
                  <template class="lg:text-xs" #body="{data}">
                    <span class="mr-2">{{ data.itemCode }} -</span>
                    <span class="mr-2">{{ data.itemName }}</span>
                  </template>
                </Column>
                <Column field="lote" header="Lote" bodyClass="lg:text-xs" headerClass="lg:text-xs">
                  <template class="lg:text-xs block" #body="{data}">
                    <div class="block">
                      <div>
                        <span class="mr-2">N°: {{ data.lote }} </span>
                      </div>
                      <div>
                        <span class="mr-2">Fecha ven: {{ data.fecha_vencimiento }}</span>
                      </div>
                    </div>
                  </template>
                </Column>
                <Column field="cantidadMdCaja" header="Cantidad" bodyClass="lg:text-xs" headerClass="lg:text-xs"></Column>
                <template #groupheader="slotProps">
                  <span class="font-bold lg:text-xs">Caja N° {{slotProps.data.cajaId}}</span>
                  <div class="flex mx-2 items-center" v-if="slotProps.data.estadoCaja">
                    <div class="flex">
                      <span class="lg:text-xs flex mx-2 text-green-600">RECEPCIONADA</span>
                      <i class="pi text-green-600 pi-check"></i>
                    </div>
                    <div class="mx-4" v-if="slotProps.data.cadena_frio">
                      <p>
                        Temperatura de llegada de la caja: <span :class="validarTemperatura(slotProps.data) ? 'text-red-600' : ''" class="font-bold">{{ slotProps.data.temp_entrada }} °</span>
                      </p>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="cajasRecibidas.some(a => a.cajaId === slotProps.data.cajaId)" class="flex mx-2 items-center">
                      <div class="flex">
                        <span class="lg:text-xs flex mx-2 text-green-600">RECEPCIONADA</span>
                        <i class="pi text-green-600 pi-check"></i>
                      </div>
                      <div class="flex items-center mx-2" v-if="slotProps.data.cadena_frio">
                        <div v-if="slotProps.data.temp_entrada === 0 || null">
                          <div class="flex items-center" v-if="cajasRecibidas.filter(a => a.cajaId === slotProps.data.cajaId)[0].temperaturaLlegada === ''">
                            <p class="text-sm">
                              Ingrese aqui la temperatura de la caja
                            </p>
                            <InputNumber inputClass="w-20" class="mx-2 w-20" inputId="temperature" suffix="℃" :min="0" :max="60" v-model="slotProps.data.temperaturaLlegada" />
                            <Button icon="pi pi-save" @click="guardarTemperaturaCaja(slotProps.data)" class="p-button-rounded p-button-info" />
                          </div>
                          <div v-else>
                            <p>
                              Temperatura de llegada de la caja: <span :class="validarTemperatura(slotProps.data) ? 'text-red-600' : ''" class="font-bold">{{ cajasRecibidas.filter(a => a.cajaId === slotProps.data.cajaId)[0].temperaturaLlegada }} °</span>
                            </p>
                          </div>
                        </div>
                        <div v-else>
                          <p>
                            Temperatura de llegada de la caja: <span :class="validarTemperatura(slotProps.data) ? 'text-red-600' : ''" class="font-bold">{{ slotProps.data.temp_entrada }} °</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-else class="flex items-center">
                      <button class="flex items-center" @click="notificarCaja(slotProps.data, 2)">
                        <div class="flex items-center mr-2">
                          <span class="lg:text-xs mx-2 text-gray-600">EN TRANSITO</span>
                          <i class="pi pi-check-square mr-2"></i>
                        </div>
                      </button>
                    </div>
                  </div>
                </template>
                <template  #groupfooter="slotProps">
                    <td colspan="4" style="text-align: right" class="font-bold text-xs">Total medicamentos</td>
                    <td class="text-xs">{{calculateMdTotal(slotProps.data.cajaId)}}</td>
                </template>
            </DataTable>
          </div>
          <div class="my-4" v-if="showCardUploadGuide">
            <div class="shadow-md p-4 flex justify-center">
              <div class="w-full md:w-6/12 border-dashed rounded-md">
                <FilePond ref="pond"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <div v-if="infoModalTransito.estado === 'POR RECEPCIONAR'" class="w-full flex justify-center gap-4">
          <Button label="CANCELAR"  @click="closeModalTransito(1)" class="bg-gray-300 hover:bg-gray-300 text-gray-800 hover:text-gray-800 border-0"/>
          <Button label="REGISTRAR CAJA" v-if="cajasRecibidas.length"  @click="closeModalTransito(2)" class="text-white hover:text-white border-0"/>
          <Button label="RECEPCIONAR" v-else  @click="$router.push({name: 'pharmasan.compras.logistica.recepcion-tecnica.recepcion', params: {id: infoModalTransito.id}})" class="bg-green-400 hover:bg-green-400 text-white hover:text-white border-0" />
        </div>
        <div v-else class="flex justify-center">
          <Button label="CANCELAR"  @click="closeModalTransito(1)" class="bg-gray-300 hover:bg-gray-300 text-gray-800 hover:text-gray-800 border-0"/>
          <Button v-if="!showCardUploadGuide" label="NOTIFICAR MERCANCIA EN DESTINO"  @click="showCardUploadGuide = true" class="bg-green-400 hover:bg-green-400 text-white hover:text-white border-0" />
          <Button v-else label="GUARDAR"  @click="closeModalTransito(2)" class="bg-green-400 hover:bg-green-400 text-white hover:text-white border-0" />
        </div>
      </template>
    </Dialog>
    <Dialog v-model:visible="displayModalNovedades" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
      <template #header>
        <div>
          <p>
            Novedades del oficio número <span class="font-bold">{{ novedadesPorMedicamento.oficio }}</span> recibido el <span class="font-bold">{{ novedadesPorMedicamento.fechaRecepcion }}</span>
          </p>
        </div>
      </template>
      <div v-if="novedadesPorMedicamento.medicamentos.length">
        <Accordion class="shadow-md gap-4" :activeIndex="0">
          <AccordionTab v-for="(medicamento, k) in novedadesPorMedicamento.medicamentos" :key="k">
            <template #header>
              <div class="flex justify-between w-full">
                <div class="flex text-xs">
                  <span class="font-bold mr-4">{{medicamento.itemCode}}</span>
                  <span class="font-bold mr-4">{{medicamento.itemName}}</span>
                </div>
              </div>
            </template>
            <DataTable
              :value="medicamento.novedades"
              responsiveLayout="scroll"
              headerClass="bg-white"
            >
              <template #empty>
                No existen novedades registradas
              </template>
              <Column field="Novedad" header="Novedad" headerClass="text-xs" class="shadow-sm" style="color: #4A4A4A;">
                <template #body="{data}">
                  <p class="text-xs flex items-center">
                    {{data.novedad}}
                  </p>
                </template>
              </Column>
              <Column field="cantidad" header="Cantidad" headerClass="text-xs" class="shadow-sm" style="color: #4A4A4A;">
                <template #body="{data}">
                  <p class="text-xs flex items-center">
                    {{data.cantidad}}
                  </p>
                </template>
              </Column>
              <Column header="Observación"
                headerClass="text-xs"
                dataType="date"
                style="color: #4A4A4A;"
                class="shadow-sm"
                field="comentario"
              >
                <template class="text-xs" #body="{data}">
                  <p class="text-xs flex items-center">
                    {{ data.comentario }}
                  </p>
                </template>
              </Column>
              <Column header="Solucion"
                headerClass="text-xs"
                dataType="date"
                style="color: #4A4A4A;"
                class="shadow-sm"
                field="comentario"
              >
                <template class="text-xs" #body="{data}">
                  <!-- <Dropdown v-model="data.solucionado" :options="solucionArray" optionLabel="name" optionValue="id" /> -->
                  <Dropdown v-model="data.solucionado" optionValue="id" :options="solucionArray">
                    <template #value="slotProps">
                      <div class="flex justify-between items-center" v-if="slotProps.value">
                        <p class="mr-2">{{slotProps.value === 1 ? 'SOLUCIONADO' : 'SIN SOLUCIONAR'}}</p>
                        <i class="pi pi-angle-down"></i>
                      </div>
                    </template>
                    <template #option="slotProps">
                      <div>
                        <p>
                          {{slotProps.option.name}}
                        </p>
                      </div>
                    </template>
                  </Dropdown>
                </template>
              </Column>
            </DataTable>
          </AccordionTab>
        </Accordion>
        <div class="flex gap-4 justify-end mt-2">
          <Button
            label="CANCELAR"
            @click="closeModalNovedades(1)"
            class="bg-gray-300 hover:bg-gray-300 text-gray-800 hover:text-gray-800 border-0"/>
          <Button
            label="ACEPTAR"
            @click="closeModalNovedades(2)"
            class="bg-blue-600 hover:bg-gray-300 text-white-800 hover:text-white-800 border-0"/>
        </div>
      </div>
      <p v-else>
        Este oficio no cuenta con novedades
      </p>
    </Dialog>
    <Dialog v-model:visible="displayModalUploadActa" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}" :modal="true">
      <template #header>
        <div>
          Adjunte aqui el acta de la recepción <span class="font-bold">{{ dataModalActa.id }}</span>
        </div>
      </template>
      <FilePond ref="pond"/>
      <template #footer>
        <div class="w-full flex justify-center gap-4">
          <Button label="CANCELAR"  @click="closeModalUploadActa" class="bg-gray-300 hover:bg-gray-300 text-gray-800 hover:text-gray-800 border-0"/>
          <Button label="REGISTRAR"  @click="subirActa" class="text-white hover:text-white border-0"/>
        </div>
      </template>
    </Dialog>
  </template>
  <script>
    import BodegasService from '../../../../services/bodegas.service'
    import RecepcionService from '../../../../services/recepcion.service.js'
    import RecepcionStore from '../../../../store/recepcion.store'
    import FilePondStore from '../../../../store/filepond.store'
    import MenuRecepcionStore from '../../../../store/menuRecepcion.store'
    import controlEnvioStore from '../../../../store/controlEnvioStore'
    import { error } from '../../../../../../../../libs/mensajes'
    import FilePond from '../FilePond/index.vue'
    import dayjs from 'dayjs'
    import { ref, computed, onMounted, watch } from 'vue'
    import Swal from 'sweetalert2'
    export default {
    name: 'ComponenteEstadoRecepcion',
    components: {
      FilePond
    },
    setup () {
      // Services
      const _BodegasService = ref(new BodegasService())
      const _RecepcionService = ref(new RecepcionService())
      // References
      const offset = ref(0)
      const page = ref(0)
      const limit = ref(10)
      const totalItemsCount = ref(10)
      const bodegas = ref([])
      const displayModal = ref(false)
      const displayModalTransito = ref(false)
      const displayModalNovedades = ref(false)
      const dataModalRecepcion = ref({})
      const guia = ref({})
      const menu = ref()
      const pond = ref()
      const medicamentosTrasito = ref([])
      const showCardUploadGuide = ref(false)
      const displayModalUploadActa = ref(false)
      const recepciones = ref([])
      const fileToOpen = ref('')
      const infoModalTransito = ref({})
      const cajasRecibidas = ref([])
      const cajasYMd = ref([])
      const expandedRowGroups = ref()
      const barCodeBox = ref('')
      const novedadesPorCaja = ref([])
      const detalleCajas = ref([])
      const dataModalActa = ref({})
      const comentarioNovedadCaja = ref('')
      const acta = ref({})
      const barCodeBoxCajaFocus = ref()
      const novedadesPorMedicamento = ref({
        oficio: '',
        fechaRecepcion: '',
        medicamentos: []
      })
      // Computed
      const adjunto = computed(
        () => FilePondStore.getters.adjunto
      )
      // Data
      const env = process.env.VUE_APP_MICROSERVICE_API_RECEPCION_TECNICA.split('/api')[0]
      const verDocumentosSelect = ref(['DOC. GUÍA', 'DOC. OFICIO'])
      const estados = ref(['EN TRANSITO', 'POR RECEPCIONAR', 'EN STOCK', 'EN ESCALA'])
      const solucionArray = ref([
        {
          name: 'SOLUCIONADO',
          id: 1
        },
        {
          name: 'SIN SOLUCIONAR',
          id: 2
        }
      ])
      const novedadesFiltro = ref(['SOLUCIONADAS', 'SIN SOLUCIONAR', 'TODAS'])
      const filtros = ref(
      {
        fechaInicial: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
        fechaFinal: dayjs().format('YYYY-MM-DD'),
        guia: '',
        oficio: '',
        estado: 'POR RECEPCIONAR',
        bodegaDestino: '',
        novedades: '',
        notificacion: false
      })
      // Computed
      const params = computed(() => {
        return {
          offset: offset.value,
          page: page.value,
          limit: limit.value,
          filters: filtros.value
        }
      })
      const notificacionOficio = computed(() => RecepcionStore.getters._filtroNotificacionOficio)
      const oficioCodeBar = computed(() => RecepcionStore.getters._barCodeOficio)
      // Methods
      const getBodegas = () => {
        _BodegasService.value.get().then(({ data }) => {
          bodegas.value = data
        })
      }
      const getRecepciones = (parameters, find) => {
        _RecepcionService.value.paginate(parameters).then(({ data }) => {
          totalItemsCount.value = data.count
          recepciones.value = data.rows
          if (find) {
            console.log('this is recepciones-------------------------------->', recepciones.value[0])
            if (recepciones.value[0].estado === 'EN STOCK') {
              openModal(recepciones.value[0])
            } else {
              openModalTransito(recepciones.value[0].id, true)
            }
            notificarCaja(oficioCodeBar.value, 1)
          }
        })
      }
      const calcularDiasEntrega = (object) => {
        const inicio = dayjs()
        const fin = dayjs(`${object.fechaEnvio}`)
        if (inicio.diff(fin, 'day') >= 3 && object.estado === 'EN TRANSITO') {
          return true
        } else {
          return false
        }
      }
      const openModal = (info) => {
        _RecepcionService.value.find(info.id).then(({ data }) => {
          info.Medicamentos = []
          for (const medicamento of data.details) {
            info.Medicamentos.push(
              {
                Articulo: medicamento.itemCode,
                Medicamento: medicamento.itemName,
                Id: medicamento.id,
                lote: medicamento.lote,
                fechaVencimiento: medicamento.fecVen,
                cantEnv: medicamento.cantidad,
                cantRec: 22
              }
            )
          }
        })
        dataModalRecepcion.value = info
        displayModal.value = true
      }
      const openModalNovedades = (oficio) => {
        novedadesPorMedicamento.value.oficio = oficio.oficio
        novedadesPorMedicamento.value.fechaRecepcion = oficio.FechaRecepcion
        _RecepcionService.value.novedadesByOficio(oficio.oficio).then(({ data }) => {
          for (const i of data) {
            if (novedadesPorMedicamento.value.medicamentos.length) {
              if (novedadesPorMedicamento.value.medicamentos.some(a => a.itemCode === i.itemCode)) {
                novedadesPorMedicamento.value.medicamentos.filter(e => e.itemCode === i.itemCode)[0].novedades.push({
                  cantidad: i.cantidad,
                  novedad: i.novedad,
                  comentario: i.comentario,
                  solucionado: i.solucionado === false ? 2 : 1,
                  id: i.id
                })
              } else {
                novedadesPorMedicamento.value.medicamentos.push({
                  itemCode: i.itemCode,
                  itemName: i.itemName,
                  lote: i.lote,
                  novedades: [{
                    cantidad: i.cantidad,
                    novedad: i.novedad,
                    comentario: i.comentario,
                    solucionado: i.solucionado === false ? 2 : 1,
                    id: i.id
                  }]
                })
              }
            } else {
              novedadesPorMedicamento.value.medicamentos.push({
                itemCode: i.itemCode,
                itemName: i.itemName,
                lote: i.lote,
                novedades: [{
                  cantidad: i.cantidad,
                  novedad: i.novedad,
                  comentario: i.comentario,
                  solucionado: i.solucionado === false ? 2 : 1,
                  id: i.id
                }]
              })
            }
          }
        })
        displayModalNovedades.value = true
      }
      const closeModalNovedades = (type) => {
        if (type === 1) {
          displayModalNovedades.value = false
        } else {
          const array = []
          for (const i of novedadesPorMedicamento.value.medicamentos) {
            i.novedades.map(a => {
              if (a.solucionado === 1) {
                array.push({ id: a.id, solucionado: true })
              }
            })
          }
          _RecepcionService.value.changeStateNovedades(array).then(({ data }) => {
            Swal.fire({
              title: 'Guardado',
              icon: 'success',
              text: 'Las novedades se actualizaron con éxito',
              confirmButtonText: 'Ok'
            })
          })
          displayModalNovedades.value = false
        }
        novedadesPorMedicamento.value = {
          oficio: '',
          fechaRecepcion: '',
          medicamentos: []
        }
      }
      const openModalTransito = (id, openOutside) => {
        _RecepcionService.value.find(id).then(({ data }) => {
          infoModalTransito.value = data
        })
        _RecepcionService.value.detalleCaja(id).then(({ data }) => {
          medicamentosTrasito.value = data
          medicamentosTrasito.value.map(a => {
            a.temperaturaLlegada = 0
          })
          for (const i of data) {
            if (!i.estadoCaja) {
              if (detalleCajas.value.find(a => a.cajaId === i.cajaId)) {
                detalleCajas.value.filter(a => a.cajaId === i.cajaId)[0].cantidad += 1
              } else {
                detalleCajas.value.push({
                  cajaId: i.cajaId,
                  cantidad: 1
                })
              }
            }
            if (cajasYMd.value.length) {
              if (cajasYMd.value.some(r => r.cajaId === i.cajaId)) {
              } else {
                cajasYMd.value.push({
                  cajaId: i.cajaId,
                  state: true,
                  temperaturaLlegada: '',
                  cadena_frio: i.cadena_frio
                })
              }
            } else {
                cajasYMd.value.push({
                  cajaId: i.cajaId,
                  state: true,
                  temperaturaLlegada: '',
                  cadena_frio: i.cadena_frio
                })
            }
          }
          if (openOutside) {
            cajasYMd.value.map(a => {
              if (a.cajaId === parseInt(oficioCodeBar.value)) {
                cajasRecibidas.value.push({
                  cajaId: a.cajaId,
                  state: true,
                  temperaturaLlegada: '',
                  cadena_frio: a.cadena_frio
                })
              }
            })
          }
        }).catch((err) => {
          error(err.response.data.message)
          barCodeBox.value = ''
        })
        displayModalTransito.value = true
      }
      const closeModalTransito = async (type) => {
        if (type === 1) {
          displayModalTransito.value = false
          showCardUploadGuide.value = false
          medicamentosTrasito.value = []
          cajasRecibidas.value = []
          infoModalTransito.value = {}
        } else {
          if (cajasRecibidas.value.length) {
            let pass = false
            if (cajasRecibidas.value.some(a => a.cadena_frio)) {
              pass = cajasRecibidas.value.filter(a => a.cadena_frio === true).some(e => e.temperaturaLlegada === '')
            }
            if (!pass) {
              if (infoModalTransito.value.estado === 'POR RECEPCIONAR') {
                const object = {
                  cajas: cajasRecibidas.value
                }
                _RecepcionService.value.put(infoModalTransito.value.id, object).then(() => {
                  Swal.fire(
                    'Buen trabajo!',
                    'Recepcionaste este envio',
                    'success'
                  ).then(() => {
                    displayModalTransito.value = false
                    showCardUploadGuide.value = false
                    medicamentosTrasito.value = []
                    cajasRecibidas.value = []
                    infoModalTransito.value = {}
                    getRecepciones(params.value)
                  })
                })
              } else {
                if (infoModalTransito.value.totalCajas === cajasRecibidas.value.length) {
                  await pond.value.onSubmitSupport().then(() => {
                    guia.value = {
                      urlPath: adjunto.value.urlPath,
                      fileName: 'guia'
                    }
                  })
                  const object = {
                    cajas: cajasRecibidas.value,
                    guia: guia.value
                  }
                  _RecepcionService.value.put(infoModalTransito.value.id, object).then(() => {
                    Swal.fire(
                      'Buen trabajo!',
                      'Recepcionaste este envio',
                      'success'
                    ).then(() => {
                      displayModalTransito.value = false
                      showCardUploadGuide.value = false
                      medicamentosTrasito.value = []
                      cajasRecibidas.value = []
                      infoModalTransito.value = {}
                      getRecepciones(params.value)
                    })
                  })
                } else {
                  Swal.fire({
                    title: 'Advertencia',
                    text: 'Tiene cajas pendientes por notificar, esta seguro de recepcionar este envio con la novedad de cajas faltantes?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Notificar mercancia en destino con novedad',
                    cancelButtonText: 'Ingresar caja faltante'
                  }).then((result) => {
                    if (result.isConfirmed) {
                      pond.value.onSubmitSupport().then(() => {
                        if (adjunto.value.urlPath) {
                          guia.value = {
                            urlPath: adjunto.value.urlPath,
                            fileName: 'guia'
                          }
                        }
                      })
                      for (const i of medicamentosTrasito.value) {
                        if (cajasRecibidas.value.some(a => a.cajaId === i.cajaId)) {
                        } else {
                          novedadesPorCaja.value.push({
                            doc_num: infoModalTransito.value.numOficio,
                            caja_id: i.cajaId,
                            comentario: comentarioNovedadCaja.value,
                            envio_id: infoModalTransito.value.id,
                            solucionado: false
                          })
                          cajasRecibidas.value.push({
                            cajaId: i.cajaId,
                            temperaturaLlegada: '',
                            state: false
                          })
                        }
                      }
                      _RecepcionService.value.novedadesByCaja(novedadesPorCaja.value).then(({ data }) => {
                        medicamentosTrasito.value = []
                        cajasRecibidas.value = []
                      })
                      const object = {
                        cajas: cajasRecibidas.value,
                        guia: guia.value
                      }
                      _RecepcionService.value.put(infoModalTransito.value.id, object).then(() => {
                        displayModalTransito.value = false
                        showCardUploadGuide.value = false
                        getRecepciones(params.value)
                        infoModalTransito.value = {}
                      })
                    }
                  })
                }
              }
            } else {
              Swal.fire(
                'Advertencia',
                'Por favor ingrese la temperatura de todas las cajas registradas',
                'warning'
              )
            }
          } else {
            Swal.fire(
              'Advertencia',
              'Por favor registre al menos una caja para continuar',
              'warning'
            )
          }
        }
        barCodeBox.value = ''
        detalleCajas.value = []
        cajasYMd.value = []
        RecepcionStore.commit('setBarCodeOficio', 1)
      }
      const findOficioByCaja = (cajaId, type) => {
        if (!type) {
          _RecepcionService.value.findOficioByCaja(cajaId).then(({ data }) => {
            filtros.value.fechaInicial = ''
            filtros.value.notificacion = true
            filtros.value.oficio = data
            getRecepciones(params.value, { find: true })
            barCodeBox.value = ''
          }).catch((err) => {
              RecepcionStore.commit('setBarCodeOficio', 1)
              error(err.response.data.message)
            })
        }
      }
      const notificarCaja = (data, type) => {
        if (type === 1) {
          if (barCodeBox.value !== '') {
            for (const caja of cajasYMd.value) {
              if (cajasRecibidas.value.length) {
                if (cajasRecibidas.value.some(e => e.cajaId === parseInt(data))) {
                  Swal.fire(
                    `La caja ${parseInt(data)} ya se encuentra registrada`,
                    '',
                    'info'
                  ).then(() => {
                    barCodeBox.value = ''
                  })
                  return
                } else {
                  if (caja.cajaId === parseInt(data)) {
                    cajasRecibidas.value.push({
                      cajaId: caja.cajaId,
                      state: true,
                      temperaturaLlegada: '',
                      cadena_frio: caja.cadena_frio
                    })
                    barCodeBox.value = ''
                  }
                }
              } else {
                if (caja.cajaId === parseInt(data)) {
                  cajasRecibidas.value.push({
                    cajaId: caja.cajaId,
                    state: true,
                    temperaturaLlegada: '',
                    cadena_frio: caja.cadena_frio
                  })
                  barCodeBox.value = ''
                } else {
                  Swal.fire(
                    'Advertencia',
                    `La caja ${barCodeBox.value} no pertenece a este oficio`,
                    'warning'
                  ).then(() => {
                    barCodeBox.value = ''
                  })
                }
                return
              }
            }
          }
        } else {
          Swal.fire({
            title: 'Advertencia',
            text: `Esta seguro de notificar la llegada de esta caja manualmente la caja ${data.cajaId}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Notificar',
            cancelButtonText: 'cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              const object = {
                cajaId: data.cajaId,
                temperaturaLlegada: '',
                state: true,
                cadena_frio: data.cadena_frio
              }
              cajasRecibidas.value.push(object)
              Swal.fire(
                'Caja recibida',
                '',
                'success'
              )
              barCodeBox.value = ''
            }
          })
        }
        if (barCodeBox.value !== '') {
          return (
            Swal.fire(
              'Advertencia',
              `La caja ${barCodeBox.value} no pertenece a este oficio`,
              'warning'
            ).then(() => {
              barCodeBox.value = ''
            })
          )
        }
      }
      const guardarTemperaturaCaja = (data) => {
        cajasRecibidas.value.map(a => {
          if (a.cajaId === data.cajaId) {
            a.temperaturaLlegada = data.temperaturaLlegada
          }
        })
      }
      const calculateMdTotal = (caja) => {
            let total = 0
            if (medicamentosTrasito.value) {
                for (const medicamento of medicamentosTrasito.value) {
                    if (medicamento.cajaId === caja) {
                        total++
                    }
                }
            }
            return total
        }
      const recepcionar = (id) => {
        MenuRecepcionStore.commit('setTab', id)
      }
      const buscar = () => {
        getRecepciones(params.value)
      }
      const limpiar = () => {
        filtros.value = {
          fechaInicial: dayjs().format('YYYY-MM-DD'),
          fechaFinal: dayjs().format('YYYY-MM-DD'),
          guia: '',
          oficio: '',
          estado: '',
          bodegaDestino: '',
          notificacion: false
        }

        getRecepciones(params.value)
      }
      const rowClass = (data) => {
        return data.id ? 'rowStyle' : null
      }
      const verPdf = (data) => {
        if (fileToOpen.value === 'DOC. GUÍA') {
          _RecepcionService.value.verPdf({ path: data.pathGuia })
        } else {
          const dataOffice = {
            idEnvio: parseInt(data.id),
              tipoOficio: '1'
          }
          controlEnvioStore.dispatch('oficioTransferencia', dataOffice)
        }
      }
      const validarTemperatura = (temp) => {
        const data = cajasRecibidas.value.filter(a => a.cajaId === temp.cajaId)
        console.log(temp, data)
        if (data[0].temperaturaLlegada > 8 || data[0].temperaturaLlegada < 2) {
          return true
        } else {
          return false
        }
      }
      const verActa = (data) => {
        _RecepcionService.value.verPdf({ path: data.pathActa })
      }
      const openModalUploadActa = (data) => {
        dataModalActa.value = data
        displayModalUploadActa.value = true
      }
      const closeModalUploadActa = () => {
        dataModalActa.value = {}
        displayModalUploadActa.value = false
      }
      const subirActa = () => {
        pond.value.onSubmitSupport().then(() => {
          acta.value = {
            urlPath: adjunto.value.urlPath,
            fileName: 'guia'
          }
          const object = {
            acta: acta.value
          }
          _RecepcionService.value.registrarActa(dataModalActa.value.id, object).then(() => {
            Swal.fire(
              'Buen trabajo!',
              'Recepcionaste este envio',
              'success'
            ).then(() => {
              displayModalUploadActa.value = false
              getRecepciones(params.value)
            })
          })
        })
      }
      const sort = (evt) => {
        console.log({ evt })
      }
      const onPage = ({ first, page, pageCount, rows }) => {
        limit.value = rows
        getRecepciones(params.value)
      }
      watch(filtros.value, () => {
        RecepcionStore.commit('setFiltros', filtros.value)
      })
      watch(oficioCodeBar, (n, v) => {
        let type = false
        n === 1 ? type = true : type = false
        findOficioByCaja(n, type)
      })
      watch(displayModalNovedades, () => {
        if (!displayModalNovedades.value) {
          novedadesPorMedicamento.value = {
            oficio: '',
            fechaRecepcion: '',
            medicamentos: []
          }
        }
      })
      watch(notificacionOficio, (n, v) => {
        if (n) {
          if (notificacionOficio.value.todas) {
            filtros.value.notificaciones = notificacionOficio.value.oficio
            filtros.value.oficio = ''
            filtros.value.fechaInicial = ''
            filtros.value.estado = ''
            filtros.value.fechaFinal = dayjs(notificacionOficio.value.fechaFin).format('YYYY-MM-DD')
            filtros.value.notificacion = true
          } else {
            filtros.value.oficio = notificacionOficio.value.oficio
            filtros.value.fechaInicial = dayjs(notificacionOficio.value.fecha).format('YYYY-MM-DD')
            filtros.value.bodegaDestino = ''
            filtros.value.guia = ''
            filtros.value.estado = ''
            filtros.value.notificacion = false
          }
          buscar()
        }
      })
      onMounted(() => {
        getBodegas()
        getRecepciones(params.value)
      })
      return {
        recepciones,
        rowClass,
        sort,
        onPage,
        notificacionOficio,
        totalItemsCount,
        offset,
        limit,
        params,
        bodegas,
        filtros,
        displayModal,
        openModal,
        dataModalRecepcion,
        buscar,
        limpiar,
        showCardUploadGuide,
        pond,
        guia,
        estados,
        recepcionar,
        guardarTemperaturaCaja,
        verPdf,
        verDocumentosSelect,
        menu,
        fileToOpen,
        closeModalNovedades,
        displayModalNovedades,
        env,
        openModalNovedades,
        novedadesPorMedicamento,
        novedadesFiltro,
        solucionArray,
        calcularDiasEntrega,
        displayModalTransito,
        openModalTransito,
        closeModalTransito,
        infoModalTransito,
        expandedRowGroups,
        calculateMdTotal,
        medicamentosTrasito,
        notificarCaja,
        cajasRecibidas,
        barCodeBox,
        detalleCajas,
        adjunto,
        novedadesPorCaja,
        comentarioNovedadCaja,
        barCodeBoxCajaFocus,
        displayModalUploadActa,
        openModalUploadActa,
        subirActa,
        dataModalActa,
        closeModalUploadActa,
        verActa,
        validarTemperatura
      }
    }
  }
  </script>

  <style scoped>
  ::v-deep(.p-rowgroup-header td) {
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
}
  .p-button-por-recepcionar {
    color: #14999A;
    border: 1px solid #14999A;
  }
  .p-button-por-recepcionar:hover {
    color: #14999A;
    border: 1px solid #14999A;
  }
  .recepcion-stock {
    color: #43B62D;
  }
  .recepcion-por-recepcionar {
    color: #14999A;
  }
  .recepcion-en-transito {
    color: #6A6A6A;
  }
  .recepcion-escala {
    color: #1769aa;
  }
  ::-webkit-scrollbar {
    width: 0.2rem;
  }
  .w-fit {
    width: fit-content;
  }
  /* ::-webkit-scrollbar:active {
    width: 0.5rem;
  } */

  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(189, 189, 189);
    border-radius: 9999px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  ::v-deep(.p-dropdown-trigger) {
    display: none;
  }
  ::v-deep(.p-paginator .p-dropdown .p-dropdown-label) {
    padding-right: 0.8rem;
  }
  :global(.swal2-container) {
    z-index: 999999 !important;
  }
  </style>
